import React from "react";
import { graphql, Link } from "gatsby";
import { DefaultLayout, SidebarLayout } from "../../layouts";
import { Hero, LinkBox } from "../../components";
import { Body } from "../../layouts/SidebarLayout/SidebarLayout";

export const AboutPageTemplate = ({data} : any) => {
    debugger;
    return (
    <DefaultLayout>
        <Hero showBox={true} image={data.about.hero.image} boxText={data.about.hero.text} />

        <SidebarLayout
            body={
            <Body dangerouslySetInnerHTML={{ __html: data.page.html }} />
            }
            sidebar={
                <LinkBox
                    title={data.about.sidebar.linkBox.title}
                    color="#000000"
                    links={data.about.sidebar.linkBox.links}
                />
            }
        />
    </DefaultLayout>
)};

export const query = graphql`{
    about: advisersAndServiceProvidersJson {
        hero {
            image
            text
        }
        sidebar {
            linkBox {
                title
                links {
                    text
                    url
                }
            }
        }
    }
    page: markdownRemark(fileAbsolutePath: { regex: "/(advisers-and-service-providers.md)/" }) {
      html
      frontmatter {
        hero {
          showBox
          boxText
          image
        }
        sidebar {
          boxes {
            title
            color
            links {
              text
              url
              isExternal
            }
          }
        }
      }
    }
}
`;

export default (props: any) =>  {
    return (<AboutPageTemplate data={props.data}/>);
};
